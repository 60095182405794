<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    :class="bgColorClass"
  >
    <v-row
      v-if="!events.length"
    >
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            イベント情報がありません。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters
      class="mt-5 mb-10 px-4"
      v-for="event in events"
      :key="event.event_id"
    >
      <!-- タイトル -->
      <v-col cols="12">
        <v-card
          class="pa-3 mb-1 rounded-b-0"
          color="primary"
        >
          <v-list-item-title class="mb-1 pl-3 font-weight-bold">
            <h3>{{ event.title }}</h3>
          </v-list-item-title>
        </v-card>
      </v-col>

      <!-- 画像 -->
      <v-col cols="12" sm="5"
        class="pa-5 secondary rounded-bl"
      >
        <v-img
          :src="event.image_url"
          :lazy-src="lazyload_img"
          max-width="500px"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="accent"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>

      <!-- 文章 -->
      <v-col cols="12" sm="7"
        class="pa-5 secondary rounded-br"
      >
        <v-list-item-content class="pa-0">
          <p v-html="event.text"
            class="event-text"
          ></p>
        </v-list-item-content>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import { LAYZLOAD_IMG } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      events: [],
      lazyload_img: LAYZLOAD_IMG,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    bgColorClass() {
      if (this.setting.site_bg_image_url) {
        return ''
      }
      else if (this.setting.is_dark_theme) {
        return 'secondary darken-1'
      }
      else {
        return 'secondary lighten-1'
      }
    }
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    this.getNews()
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    // API req: イベントデータ
    getNews() {
      this.apiTool.getRequest('common/event/', {limit:10, offset:0}).then( results => {
        if (!results || !results.length) return

        this.events = results
      })
      .catch( error => AlertApiError(error) )
    },
  }
}
</script>

<style scoped>
.v-list {
  overflow-y: scroll;
}
.event-text {
  line-height: 1.5em;
}
</style>
